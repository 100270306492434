import React from 'react';
import styles from './About.module.scss';

import H2Element from '../../atoms/H2Element/H2Element';
import Skills from '../Skills/Skills';
import borderLine from '../../../assets/images/border-line-home.svg';

const about = () => (
    <div className={styles.About} id="skills">
        <img src={borderLine} alt="Border design skills" className={styles.borderLine} />
        <div className={styles.AboutContent}>
            <H2Element title="Skills" />
            <Skills />
        </div>
    </div>
);

export default about;