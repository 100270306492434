import React, { Component } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Recaptcha from 'react-recaptcha';
import emailjs from 'emailjs-com';
import styles from './ContactForm.module.scss';
import H2Element from '../../atoms/H2Element/H2Element';
import buttonCorner from '../../../assets/images/button-corner.svg';
import borderLine from '../../../assets/images/border-line-contact.svg';
import borderLineBottom from '../../../assets/images/border-line-footer.svg';

class Basic extends Component {
  constructor(props) {
    super(props);
    this.handleSubscribe = this.handleSubscribe.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
  }
  state = {
    formSendSuccess: false,
    formSendFail: false,
    isVerified: false
  }

  recaptchaLoaded() {
    console.log("Recaptcha loaded");
  }

  handleSubscribe() {
    if(this.state.isVerified) {
      this.setState({ formSendSuccess: true, formSendFail: false });
    } else {
      
    }
  }

  render() {
    let formSendSuccessMessage = null;
    if(this.state.formSendSuccess === true) {
      formSendSuccessMessage = <span className={`${styles.formMessage} ${styles.formSuccess}`}>Your message was successfully send.</span>
    }

    let formSendFailMessage = null;
    if(this.state.formSendFail === true) {
      formSendFailMessage = <span className={ `${styles.formMessage} ${styles.formFail}`}>There was a problem sending your message.</span>
    }

    const callback = function () {
      console.log('Done!!!!');
    };

    return (
      <div className={styles.formWrapper} id="contact">
        <img src={borderLine} alt="Border design top contact section" className={styles.borderLine} />
        <img src={borderLineBottom} alt="Border design bottom contact section" className={styles.borderLineBottom} />
        <H2Element title="Contact" />
        <div className={styles.messages}>
          {formSendSuccessMessage}
          {formSendFailMessage}
        </div>
        <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{ name: '', email: '', message: '' }}

            validate={values => {
            let errors = {};

            if (!values.email) {
              errors.email = "Please fill in 'Email'.";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = 'Invalid email!';
            }

            if (!values.name) {
                errors.name = "Please fill in 'Name'.";
            }
            console.log(values.message.length)

            if (!values.message || values.message.length < 40) {
                errors.message = "Please fill in 'Message'.";
            }

            return errors;
          }}
          
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setSubmitting(false);
            if(this.state.isVerified === true) {
            emailjs.send('gmail','template_o2lQs1BZ', values, 'user_pWiZkDiIIle6BgJKfzmoF')
              .then(response => {
                resetForm();
                this.setState({ formSendSuccess: true, formSendFail: false });
              })
              .catch(error => {
                this.setState({ formSendFail: true, formSendSuccess: false });
              });
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className={styles.form}>
                <div className={styles.formElement}>
                  <label htmlFor="name">Name</label>
                  <Field type="text" name="name" id="name" placeholder="Your name" />
                  <ErrorMessage name="name" component="div" className={styles.errorMessage} />
                </div>
                
                <div className={styles.formElement}>
                  <label htmlFor="email">Email</label>
                  <Field type="email" name="email" id="email" placeholder="e.g. name@gmail.com" />
                  <ErrorMessage name="email" component="div" className={styles.errorMessage} />
                </div>

                <div className={styles.formElement}>
                  <label htmlFor="message">Message</label>
                  <Field name="message" component="textarea" id="message" placeholder="Your message?" />
                  <ErrorMessage name="message" component="div" className={styles.errorMessage} />
                </div>
                
                
                

                <div className={styles.buttonWrapper}>
                  <button type="submit" className={styles.button} disabled={isSubmitting}>Send</button>
                  <div className={styles.topCorner}></div>
                  <div className={styles.bottomCorner}></div>
                  <img src={buttonCorner} alt="button corner top" className={styles.buttonCornerTop} />
                  <img src={buttonCorner} alt="button corner bottom" className={styles.buttonCornerBottom} />
                </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default Basic;