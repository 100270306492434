import React from 'react';
import styles from './NoMatch.module.scss';
import Logo from '../../../assets/images/404-logo.png';
import { Link } from 'react-router-dom';

const noMatch = () => (
    <div className={styles.noMatch}>
        <img src={Logo} alt="Jochen Meyvisch logo" />
        <h1>404 | Page not found</h1>
        <Link className={styles.link} to="/">Back to home</Link>
    </div>
);

export default noMatch;