import React from 'react';
import styles from './Projects.module.scss';
import H2Element from '../../atoms/H2Element/H2Element';
import ProjectPreview from '../../molecules/ProjectPreview/ProjectPreview';
import losTigresImg from '../../../assets/images/projects/los-tigres/home-desktop.png';
import ftacticsImg from '../../../assets/images/projects/ftactics/home-desktop.png';
import scaleCityImg from '../../../assets/images/projects/scalecity/home-desktop.png';
import pakMarioImg from '../../../assets/images/projects/pak-mario/start.png';
import borderLine from '../../../assets/images/border-line-projects.svg';

const projects = () => (
    <div className={styles.projectsWrapper} id="projects">
        <img src={borderLine} alt="Border design projects section" className={styles.borderLine} />
        <div className={styles.projects}>
            <H2Element title="Projects" />
            <div className={styles.overview}>
                <ProjectPreview link="/los-tigres" title="FC Los Tigres" background={losTigresImg} />
                <ProjectPreview link="/ftactics" title="Ftactics" background={ftacticsImg} />
                <ProjectPreview link="/scalecity" title="ScaleCity" background={scaleCityImg} />
                <ProjectPreview link="/pak-mario" title="Pak-Mario" background={pakMarioImg} />
            </div>
        </div>
    </div>
);

export default projects;