import React from 'react';
import styles from './Skill.module.scss';
import Hexagon from '../../../assets/images/hexagon.svg';

const skill = (props) => {
    const backgroundStyle = {
        backgroundImage: "url(" + Hexagon + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    };

    return (
    <div className={styles.skill}>
        <div className={styles.hexagon} style={backgroundStyle}>
            <img src={props.img} alt={props.alt} />
        </div>
        <span className={styles.name}>{props.name}</span>
    </div>
    );
}

export default skill;