import React from 'react';
import styles from './Skills.module.scss';
import Skill from '../../molecules/Skill/Skill';
import ReactIcon from '../../../assets/images/skills/react.svg';
import LaravelIcon from '../../../assets/images/skills/laravel.svg';
import WordpresslIcon from '../../../assets/images/skills/wordpress.svg';
import CraftlIcon from '../../../assets/images/skills/craft-cms.svg';
import HTMLIcon from '../../../assets/images/skills/html-5.svg';
import CSSIcon from '../../../assets/images/skills/css-3.svg';
import PHPIcon from '../../../assets/images/skills/php.svg';
import JSIcon from '../../../assets/images/skills/javascript.svg';
import GithubIcon from '../../../assets/images/skills/github.svg';
import GulpIcon from '../../../assets/images/skills/gulp.svg';
import WebpackIcon from '../../../assets/images/skills/webpack.svg';
import AdobeXdIcon from '../../../assets/images/skills/adobe-xd.svg';
import PhotoshopIcon from '../../../assets/images/skills/photoshop.svg';

const skills = () => (
    <div className={styles.skills}>
       <div className={styles.card}>
            <span className={styles.cardtitle}>Frameworks</span>
            <span className={styles.line}></span>
            <Skill name="React" img={ReactIcon} alt="ReactJS icon" />
            <Skill name="Laravel" img={LaravelIcon} alt="Laravel icon" />
            <Skill name="Wordpress" img={WordpresslIcon} alt="Wordpress icon" />
            <Skill name="Craft CMS" img={CraftlIcon} alt="Craft CMS icon" />
            <div className={styles.overlay}>FRAMEWORKS I KNOW</div>
       </div>
       <div className={styles.card}>
            <span className={styles.cardtitle}>Languages</span>
            <span className={styles.line}></span>
            <Skill name="HTML" img={HTMLIcon} alt="HTML icon" />
            <Skill name="(S)CSS" img={CSSIcon} alt="CSS icon" />
            <Skill name="JavaScript" img={JSIcon} alt="JavaScript icon" />
            <Skill name="PHP" img={PHPIcon} alt="PHP icon" />
            <div className={styles.overlay}>FRAMEWORKS I KNOW</div>
       </div>
       <div className={styles.card}>
            <span className={styles.cardtitle}>Dev tools</span>
            <span className={styles.line}></span>
            <Skill name="Github" img={GithubIcon} alt="Github icon" />
            <Skill name="Gulp" img={GulpIcon} alt="Gulp icon" />
            <Skill name="Webpack" img={WebpackIcon} alt="Webpack icon" />
            <div className={styles.overlay}>FRAMEWORKS I KNOW</div>
       </div>
       <div className={styles.card}>
            <span className={styles.cardtitle}>Design</span>
            <span className={styles.line}></span>
            <Skill name="Adobe XD" img={AdobeXdIcon} alt="Adobe XD icon" />
            <Skill name="Adobe Photoshop" img={PhotoshopIcon} alt="Adobe Photoshop icon" />
            <div className={styles.overlay}>FRAMEWORKS I KNOW</div>
       </div>
    </div>
);

export default skills;