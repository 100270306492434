import React from 'react';
import Footer from '../../organisms/Footer/Footer';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../App/ScrollToTop';

import homeDesktop from '../../../assets/images/projects/los-tigres/home-desktop.png';
import homeMobile from '../../../assets/images/projects/los-tigres/home-mobile.png';
import playersMobile from '../../../assets/images/projects/los-tigres/players-mobile.png';
import kalenderIpad from '../../../assets/images/projects/los-tigres/kalender-ipad.png';
import klassementIpad from '../../../assets/images/projects/los-tigres/klassement-ipad.png';
import contactDesktop from '../../../assets/images/projects/los-tigres/contact-desktop.png';
import topschuttersstandMobile from '../../../assets/images/projects/los-tigres/topschuttersstand-mobile.png';
import arrowLeft from '../../../assets/images/icons/left-arrow.svg';

const losTigres = (props) => {

    function returnBack() {
        return props.history.goBack();
    }

    return (
        <div className="project-page">
            <ScrollToTop />
            <Link to="/" onClick={returnBack} className="backLink">
                <img src={arrowLeft} alt="Back to home icon" />
                <span>back</span>
            </Link>
            <div className="content">
                <h1>FC Los Tigres</h1>
                <p>
                    This is a website I developed for futsall team FC Los Tigres.
                    Since they played in sunday league futsall they were in need of a website where they could
                    keep track of their matches, league standings and goal scoring stats.
                </p>
                <p>
                    I developed this website in React.JS and the UX/UI was made by <a className="link" target="_blank" rel="noopener noreferrer" href="https://timomeyvisch.com">Timo Meyvisch</a>.
                </p>
                <p>
                    Check it out at <a className="link" target="_blank" rel="noopener noreferrer" href="https://fclostigres.be">fclostigres.be</a>
                </p>
                <img src={homeDesktop} alt="Homepagina desktopversie" />
                <img src={contactDesktop} alt="Homepagina desktopversie" />
                <img src={kalenderIpad} alt="Homepagina desktopversie" />
                <img src={klassementIpad} alt="Homepagina desktopversie" />
                <img src={homeMobile} alt="Homepagina desktopversie" />
                <img src={playersMobile} alt="Homepagina desktopversie" />
                <img src={topschuttersstandMobile} alt="Homepagina desktopversie" />
            </div>
            <Footer />
        </div>
    );
}

export default losTigres;