import React from 'react';
import styles from './HomeHeader.module.scss';
import jochen from '../../../assets/images/jochen.png';
import Particles from 'react-particles-js';
import borderLine from '../../../assets/images/border-line-home.svg';

const homeHeader = () => {
    return (
        <header className={styles.Header}>
            <img src={borderLine} alt="Border design home" className={styles.borderLine} />
            <Particles 
                className={styles.particles} 
                width="100%" 
                height="105vh"
                params={{
                    "particles": {
                        "number": {
                            "value": 90,
                            "density": {
                                "enable": true,
                                "value_area": 800
                            }
                        },
                        "color": {
                            "value": "#0fBDe1"
                        },
                        "shape": {
                            "type": "polygon",
                            "stroke": {
                                "width": 0,
                                "color": "#ffffff"
                            },
                            "polygon": {
                            "nb_sides": 6
                            },
                            "image": {
                            "src": "img/github.svg",
                            "width": 100,
                            "height": 100
                            }
                        },
                        "opacity": {
                            "value": 0.2,
                            "random": false,
                            "anim": {
                                "enable": false,
                                "speed": 1,
                                "opacity_min": 0.2,
                                "sync": false
                            }
                        },
                        "size": {
                            "value": 2,
                            "random": true,
                            "anim": {
                                "enable": false,
                                "speed": 40,
                                "size_min": 1,
                                "sync": false
                            }
                        },
                        "line_linked": {
                            "enable": true,
                            "distance": 200,
                            "color": "#0fBDe1",
                            "opacity": 0.3,
                            "width": 1
                        },
                        "move": {
                            "enable": true,
                            "speed": 1,
                            "direction": "none",
                            "random": true,
                            "straight": false,
                            "out_mode": "out",
                            "bounce": false,
                            "attract": {
                                "enable": false,
                                "rotateX": 1200,
                                "rotateY": 1200
                            }
                        }
                    },
                    "interactivity": {
                        "detect_on": "canvas",
                        "events": {
                          "onhover": {
                            "enable": true,
                            "mode": "grab"
                          },
                          "onclick": {
                            "enable": false,
                            "mode": "repulse"
                          },
                          "resize": true
                        },
                        "modes": {
                          "grab": {
                            "distance": 400,
                            "line_linked": {
                              "opacity": 0.3
                            }
                          },
                          "bubble": {
                            "distance": 400,
                            "size": 40,
                            "duration": 2,
                            "opacity": 8,
                            "speed": 3
                          },
                          "repulse": {
                            "distance": 200,
                            "duration": 0.4
                          },
                          "push": {
                            "particles_nb": 4
                          },
                          "remove": {
                            "particles_nb": 2
                          }
                        }
                    },
                    "retina_detect": false
                }}
            />
            <div className={styles.HeaderContent}>
                <div className={styles.textWrapper}>
                    <div className={styles.text}>
                        <div className={styles.name}>
                            <h1>Jochen Meyvisch</h1>
                            <span className={styles.HeaderText}>Front-end developer</span>
                            <div className={styles.nameLine}><div className={styles.highlight}></div></div>
                        </div>
                        <p className={styles.info}>
                            I'm a passionate 22 year old Front-end developer who loves making cool websites
                            and webapplications.
                        </p>
                        <p className={styles.info}>
                            At the <span className={styles.infoHighlight}>Karel de Grote university college</span> I have obtained a <span className={styles.infoHighlight}>bachelor's degree</span> in
                             Multimedia and Communications Technology with a specialization in <span className={styles.infoHighlight}>Web &amp; UX</span>.
                            Trough this degree I have gained a broad basis of web development skills both in terms of 
                             Front-end <span className={styles.infoHighlight}>(HTML, CSS &amp; JS)</span> as well as in Back-end development <span className={styles.infoHighlight}>(PHP &amp; Python)</span>.<br />
                            I have experience working with frameworks such as <span className={styles.infoHighlight}>React, Laravel and Wordpress</span> as
                             well as using tools like Git, Gulp and Webpack.
                        </p>
                        <p className={styles.info}>
                            I'm really interested in JavaScript frameworks and I have strengthenend my React skills
                            on an independent basis through online courses.
                        </p>
                        <span className={styles.computerTextTop}>INFO</span>
                        <span className={styles.computerTextBottom}>75.7369425581 - 82.6134546782</span>
                    </div>
                </div>
                <div className={styles.imageWrapper}>
                    <div className={styles.image}>
                    <span className={styles.imageText}>IMAGE</span>
                        <img src={jochen} alt="Jochen Meyvisch" />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default homeHeader;