import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../organisms/Footer/Footer';
import ScrollToTop from '../../App/ScrollToTop';

import arrowLeft from '../../../assets/images/icons/left-arrow.svg';
import homeDesktop from '../../../assets/images/projects/ftactics/home-desktop.png';
import findIpad from '../../../assets/images/projects/ftactics/find-ipad.png';
import makeIpad from '../../../assets/images/projects/ftactics/make-ipad.png';
import homeMobile from '../../../assets/images/projects/ftactics/homepage.jpg';
import findMobile from '../../../assets/images/projects/ftactics/find-tactics.jpg';
import makeMobile from '../../../assets/images/projects/ftactics/make-tactic-editor.jpg';
import homePromo from '../../../assets/images/projects/ftactics/promo-desktop.png';

const ftactics = (props) => {

    function returnBack() {
        return props.history.goBack();
    }

    return (
        <div className="project-page">
            <ScrollToTop />
            <Link to="/" onClick={returnBack} className="backLink">
                <img src={arrowLeft} alt="Back to home icon" />
                <span>back</span>
            </Link>
            <div className="content">
                <h1>FTACTICS</h1>
                <p>
                    This is an apllication I created as my graduation project at the Karel de Grote university college.
                </p>
                <p>
                    Four years ago I started an indoor soccer team in amateur football with some friends. We soon learned that 
                    indoor football is very tactical and that many teams play with certain systems. As a team, we had a hard time 
                    with this because we had difficulty finding solutions, developing our own system and countering that of 
                    the opponent.
                    From time to time someone came up with a tactic and visualized it in the dressing room with 
                    a tactic board or on a large paper. This was often unclear, not visually strong and static. 
                    With this we also had a simple tactic for the start of the match, but we could not fall back on 
                    anything during our game or at half time.
                    From these experiences FTACTICS originated.
                </p>
                <h2>Goal</h2>
                <p>
                With this application I wanted to provide a tool for teams that play football on an amateur level 
                to create, save and view tactics in a simple and interactive way. 
                </p>
                <h2>Features</h2>
                <ul>
                    <li>Developed in a Laravel environment</li>
                    <li>Suitable for any device, with a focus on an app-like mobile experience</li>
                    <li>Editor to make the tactics with an intuitive interface</li>
                    <li>Personal account in the application so you can save, view and share your own made tactics</li>
                </ul>
                <h2>Make tactics</h2>
                <p>
                    To make your own tactics I have developed an editor using the <a className="link" target="_blank" rel="noopener noreferrer" href="https://https://konvajs.org/">Konva.js</a> framework.
                    This is an HTML5 canvas library for desktop and mobile applications.
                    You can drag objects on the field via drag &amp; drop functionality as well as select them. 
                    The editor is fully responsive so that it looks good on any device.
                </p>
                <h2>Saved tactics</h2>
                <p>
                    After saving your tactic you can watch them in an animated way. 
                    To create these animations I made use of <a className="link" target="_blank" rel="noopener noreferrer" href="https://animejs.com/">Anime.js</a>.
                    This is a lightweight animation library that has keyframing functionality.
                    A cool feature of Anime.js is that you can control the playback of the animation. 
                    You have the ability to start, stop, restart and reset the animation.
                </p>
                <h2>Find tactics</h2>
                <p>
                    The application allows you to create and view your own tactics, but there are also standard tactics 
                    built into the application to inspire you.
                </p>
                <h2>Check it out</h2>
                <p>
                    I made two websites for the application, 
                    a promotion website <a className="link" target="_blank" rel="noopener noreferrer" href="https://ftactics.com/">ftactics.com</a> and a website for the application itself <a className="link" target="_blank" rel="noopener noreferrer" href="https://app.ftactics.com/">app.ftactics.com</a>.
                </p>

                <iframe 
                    width="100%" 
                    height="480" 
                    src="https://www.youtube.com/embed/Ivhl2GgDukg" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    title="Ftactics video"
                    allowFullScreen>
                </iframe>
                <img src={homePromo} alt="Ftactics promo website desktop screenshot" />
                <img src={homeDesktop} alt="Ftactics homescreen desktop screenshot" />
                <img src={findIpad} alt="Ftactics find tactics ipad screenshot" />
                <img src={makeIpad} alt="Ftactics make tactics ipad screenshot" />
                <img src={homeMobile} alt="Ftactics homescreen mobile screenshot" />
                <img src={findMobile} alt="Ftactics find tactics mobile screenshot" />
                <img src={makeMobile} alt="Ftactics make tactics mobile screenshot" />
            </div>
            <Footer />
        </div>
    );
}

export default ftactics;