import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../organisms/Footer/Footer';
import ScrollToTop from '../../App/ScrollToTop';

import arrowLeft from '../../../assets/images/icons/left-arrow.svg';
import homeDesktop from '../../../assets/images/projects/scalecity/home-desktop.png';
import contactDesktop from '../../../assets/images/projects/scalecity/contact-desktop.png';
import homeIpad from '../../../assets/images/projects/scalecity/home-ipad.png';
import overMobile from '../../../assets/images/projects/scalecity/over-mobile.png';
import werkenMobile from '../../../assets/images/projects/scalecity/werken-mobile.png';

const scaleCity = (props) => {
    function returnBack() {
        return props.history.goBack();
    }

    return (
        <div className="project-page">
            <ScrollToTop />
            <Link to="/" onClick={returnBack} className="backLink">
                <img src={arrowLeft} alt="Back to home icon" />
                <span>back</span>
            </Link>
            <div className="content">
                <h1>ScaleCity</h1>
                <p>
                    During my internship at Calibrate I had the oppurtunity to design and develop a potential new site for
                    ScaleCity. 
                </p>
                <p>
                    I built the website with <a className="link" target="_blank" rel="noopener noreferrer" href="https://craftcms.com/">Craft CMS</a>.
                    Craft is a CMS which provieds deeper control and more powerful performance from their content management tools.
                    It has all the advantages of a CMS but gives you total freedom in development because you need to build out your own HTML, CSS and JavaScript. 
                </p>
                <img src={homeDesktop} alt="ScaleCity homepage desktop screenshot" />
                <img src={contactDesktop} alt="ScaleCity contact page desktop screenshot" />
                <img src={homeIpad} alt="ScaleCity homepage ipad screenshot" />
                <img src={overMobile} alt="ScaleCity about page mobile screenshot" />
                <img src={werkenMobile} alt="ScaleCity work page mobile screenshot" />
            </div>
            <Footer />
        </div>
    );
}

export default scaleCity;