import React from 'react';
import styles from './Footer.module.scss';
import Logo from '../../../assets/images/logo-white.png';
import githubIcon from '../../../assets/images/icons/social/github.svg';
import linkedinIcon from '../../../assets/images/icons/social/linkedin.svg';
import borderLine from '../../../assets/images/border-line-footer.svg';

const footer = () => (
    <footer className={styles.footer}>
        <img src={borderLine} alt="Border design footer" className={styles.borderLine} />
        <img src={Logo} alt="Logo JM" className={styles.logo} />
        <a className={styles.emailLink} href="mailto:jochen.meyvisch@gmail.com">jochen.meyvisch@gmail.com</a>
        <div className={styles.socials}>
        <a className="link" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/jochen-meyvisch">
            <img src={linkedinIcon} alt="LinkedIn icon" />
        </a>
        <a className="link" target="_blank" rel="noopener noreferrer" href="https://github.com/JochenM97">
            <img src={githubIcon} alt="Github icon" />
        </a>
        </div>
        <span className={styles.credits}>Handcrafted by me</span>
        <span className={styles.name}>Jochen Meyvisch</span>
    </footer>
);

export default footer;