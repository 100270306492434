import React from 'react';
import styles from './Navigation.module.scss';
import Logo from '../../../assets/images/logo-white.png';
import { Link } from 'react-router-dom';

const navigation = () => {

    function scrollToId(id) {
        let scrollToElement = document.getElementById(id);
        let position = scrollToElement.getBoundingClientRect().y;
        window.scrollTo(0, position);
    }

    return (
        <div className={styles.navigationWrapper}>
            <nav className={styles.navigation}>
                <div className={styles.imageDiv}>
                    <img src={Logo} alt="Logo JM" />
                </div>
                <div className={styles.links}>
                    <Link to="/" className={styles.navLink} onClick={scrollToId.bind(this, "skills")}>Skills</Link>
                    <Link to="/" className={styles.navLink} onClick={scrollToId.bind(this, "projects")}>Projects</Link>
                    <Link to="/" className={styles.navLink} onClick={scrollToId.bind(this, "contact")}>Contact</Link>
                </div>
            </nav>
        </div>
    );
}

export default navigation;