import React from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../organisms/Footer/Footer';
import ScrollToTop from '../../App/ScrollToTop';

import arrowLeft from '../../../assets/images/icons/left-arrow.svg';
import start from '../../../assets/images/projects/pak-mario/start.png';
import playing from '../../../assets/images/projects/pak-mario/playing.png';
import boost from '../../../assets/images/projects/pak-mario/boost.png';
import game_over from '../../../assets/images/projects/pak-mario/game_over.png';
import pakMarioGif from '../../../assets/images/projects/pak-mario/pak-mario.gif';

const pakMario = (props) => {
    function returnBack() {
        return props.history.goBack();
    }

    return (
        <div className="project-page">
            <ScrollToTop />
            <Link to="/" onClick={returnBack} className="backLink">
                <img src={arrowLeft} alt="Back to home icon" />
                <span>back</span>
            </Link>
            <div className="content">
                <h1>Pak-Mario</h1>
                <p>
                    This is a game I made in 4 days with fellow students during a bootcamp.
                    You can play as Mario in a Pac-Man style game.
                </p>
                <p>
                    We made it with <a className="link" target="_blank" rel="noopener noreferrer" href="https://phaser.io/">Phaser</a>, a HTML5 game framework.
                </p>
                <img className="image-gif" src={pakMarioGif} alt="Pak-Mario gif" />
                <img src={start} alt="Pak-Mario home screen" />
                <img src={playing} alt="Pak-Mario screenshot while playing" />
                <img src={boost} alt="Pak-Mario screenshot when getting a boost" />
                <img src={game_over} alt="Pak-Mario game over screen" />
            </div>
            <Footer />
        </div>
    );
}

export default pakMario;