import React from 'react';
import styles from './H2Element.module.scss';
import furureBorder from '../../../assets/images/future-border.svg';

const h2Element = (props) => (
    <div className={styles.H2Element}>
        <h2>{props.title}
        <img className={styles.futureborder} src={furureBorder} alt="Futuristic border bottom" /></h2>
    </div>
);

export default h2Element;