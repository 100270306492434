import React from 'react';
import styles from './ProjectPreview.module.scss';
import { Link } from 'react-router-dom';
import arrow from '../../../assets/images/icons/left-arrow.svg';

const projectpreview = (props) => {
    const backgroundStyle = {
        backgroundImage: "url(" + props.background + ")",
        backgroundPosition: "center",
        backgroundSize: "cover"
    };

    return (
        <Link to={props.link} className={styles.projectpreview} style={backgroundStyle}>
            <div className={styles.title}>
                <span className={styles.titleText}>{props.title}</span>
                <img src={arrow} alt="Arrow icon" className={styles.arrow} />
            </div>
            <div className={styles.corner}></div>
        </Link>
    );
};

export default projectpreview;