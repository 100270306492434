import React from 'react';
import Navigation from '../../organisms/Navigation/Navigation';
import HomeHeader from '../../organisms/HomeHeader/HomeHeader';
import About from '../../organisms/About/About';
import Projects from '../../organisms/Projects/Projects';
import ContactForm from '../../organisms/ContactForm/ContactForm';
import Footer from '../../organisms/Footer/Footer';

const home = () => (
    <div>
        <Navigation />
        <HomeHeader />
        <About />
        <Projects />
        <ContactForm />
        <Footer />
    </div>
);

export default home;