import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import '../pages/projects.scss';
import styles from './App.module.scss';
import Home from '../pages/Home/Home';
import LosTigres from '../pages/LosTigres/LosTigres';
import Ftactics from '../pages/Ftactics/Ftactics';
import ScaleCity from '../pages/ScaleCity/ScaleCity';
import PakMario from '../pages/PakMario/PakMario';
import NoMatch from '../pages/NoMatch/NoMatch';

function App() {
  return (
    <div className={styles.App}>
      <BrowserRouter>
        <Switch>
          <Route path="/los-tigres" component={LosTigres} />
          <Route path="/ftactics" component={Ftactics} />
          <Route path="/scalecity" component={ScaleCity} />
          <Route path="/pak-mario" component={PakMario} />
          <Route path="/" exact component={Home} />
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
